import React, { useEffect, useState } from "react";
import Select, { SingleValue, ActionMeta, GroupBase, components } from "react-select";

interface DropdownOption {
  value: string;
  label: string;
}

type SuggestedSearchBarProps = {
  dropDownOptions: readonly (DropdownOption | GroupBase<DropdownOption>)[];
  onChange: (
    newValue: SingleValue<DropdownOption | undefined>,
    actionMeta: ActionMeta<DropdownOption | undefined>,
  ) => void;
  placeholder?: string;
  value?: SingleValue<DropdownOption | undefined>;
};

const SuggestedSearchBar = ({
  dropDownOptions,
  onChange,
  placeholder,
  value,
}: SuggestedSearchBarProps) => {
  const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setMenuPortalTarget(document.body);
  }, []);

  const DropdownIndicator = (props: any) => {
    return value ? null : <components.DropdownIndicator {...props} />;
  };

  return (
    <>
      <Select
        options={dropDownOptions}
        onChange={onChange}
        isSearchable={true}
        isClearable={true}
        styles={{
          indicatorSeparator: () => ({ display: "none" }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "var(--gray0)" : "inherit",
            color: "var(--gray90)",
            paddingBottom: "12px",
            paddingTop: "12px",
            fontSize: "14px",
            lineHeight: "22px",
            "&:hover": {
              backgroundColor: "var(--gray0)",
              cursor: "pointer",
            },
          }),
          control: (provided) => ({
            ...provided,
            boxShadow:
              "0px 0px 0px 0.5px rgba(220, 226, 234, 0.2), 0px 3px 12px -3px rgba(0, 0, 0, 0.12)",
            border: "none",
            borderRadius: "6px",
            "&:hover": {
              cursor: "pointer",
            },
          }),
        }}
        placeholder={placeholder ? placeholder : "Select..."}
        components={{ DropdownIndicator }}
        value={value}
        menuPortalTarget={menuPortalTarget}
        menuPosition={"absolute"}
        menuPlacement={"auto"}
      />
    </>
  );
};

export default SuggestedSearchBar;
