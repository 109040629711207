import Cookies from "universal-cookie";
// import { User } from "./models/Entities";
// import { SEC_IN_YEAR } from "./assets/constants";
export const hey = "hey";
// const fetch = require("node-fetch");

// export interface UserSuccessData {
//   token: string;
//   user: User;
//   created: boolean;
// }
// export interface FormErrorData {
//   [key: string]: string[];
// }

// export interface MultipleFormErrorData {
//   [index: number]: FormErrorData;
// }

export const apiURLForPath = (path: string): string => {
  let baseURL = "";
  switch (process.env.NODE_ENV) {
    case "production":
      baseURL = "https://api.merge.dev/api";
      break;
    default:
      baseURL = "http://127.0.0.1:8000/api";
  }

  if (path.charAt(0) != "/") {
    return `${baseURL}/${path}`;
  }
  return baseURL + path;
};

export const fetchWithoutAuth = ({
  path,
  method = "GET",
  headers = {},
  body,
  onResponse,
  onError,
}: {
  path: string;
  method: string;
  headers?: { [key: string]: string };
  body?: any;
  onResponse: (response: any) => void;
  onError?: (response: any) => void;
}) => {
  const updatedHeaders = {
    ...headers,
    "Content-Type": "application/json;charset=UTF-8",
  };
  const url = apiURLForPath(path);

  fetch(url, {
    method,
    headers: updatedHeaders,
    body: body ? JSON.stringify(body) : null,
  })
    .then((response: any) => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .then((data: any) => {
      onResponse(data);
    })
    .catch((error: any) => {
      if (onError) {
        onError(error);
      }
    });
};

export const fetchWithAuth = ({
  path,
  method = "GET",
  headers = {},
  body,
  onResponse,
  onError,
}: {
  path: string;
  method: string;
  headers?: { [key: string]: string };
  body?: any;
  onResponse: (response: any) => void;
  onError?: (response: any) => void;
}) => {
  let updatedHeaders: { [key: string]: string } | Headers = headers || {};
  let updatedBody: any = body && method !== "GET" ? JSON.stringify(body) : null;
  const cookies = new Cookies();
  const authToken = cookies.get("auth_token");
  const csrfToken = cookies.get("csrftoken");

  let hasFile = false;
  for (const key in body) {
    if (body[key] instanceof Blob) {
      hasFile = true;
    }
  }

  if (hasFile) {
    updatedHeaders = new Headers();
    for (const key in headers) {
      updatedHeaders.append(key, headers[key]);
    }

    updatedBody = null;
    if (body) {
      updatedBody = new FormData();
      for (const key in body) {
        updatedBody.append(key, body[key]);
      }
    }
    if (authToken) {
      updatedHeaders.append("Authorization", `Token ${authToken}`);
    }
    if (csrfToken) {
      updatedHeaders.append("X-Csrftoken", csrfToken);
    }
  } else {
    if (authToken) {
      updatedHeaders.Authorization = `Token ${authToken}`;
    }
    if (csrfToken) {
      updatedHeaders["X-Csrftoken"] = csrfToken;
    }
    updatedHeaders["Content-Type"] = "application/json;charset=UTF-8";
  }

  const url = apiURLForPath(path);

  fetch(url, {
    method,
    headers: updatedHeaders,
    body: updatedBody,
  })
    .then((response: any) => {
      if (response.ok) {
        if (response.status == 204) {
          // No content
          return {};
        }
        return response.json();
      }
      if (response.status === 403) {
        response.json().then((data: any) => {
          if (data.detail === "Invalid token.") {
            const cookies = new Cookies();
            cookies.remove("auth_token");
            window.location.reload();
          }
        });
      }
      throw response;
    })
    .then((data: any) => onResponse(data))
    .catch((error: any) => {
      if (onError) {
        onError(error);
      }
    });
};

// export const fetchCurrentUser = (setUser: (response: any) => void) => {
//   fetchWithAuth({
//     path: "/users/me",
//     method: "GET",
//     onResponse: (data: User) => {
//       const cookies = new Cookies();
//       cookies.set("user_type", data.type, { maxAge: SEC_IN_YEAR });
//       setUser(data);
//     },
//   });
// };
